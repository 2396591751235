import { useTranslation } from 'react-i18next';
/* eslint-disable prefer-const */
/* eslint-disable max-statements */
/* eslint-disable no-useless-return */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../../../utils/hoverCss/index.less';
import './index.less';
import { customRedirect } from '~/utils/link/goPage';
import { logAssociationLink, logMedia } from '~/utils/log';
import { getSpecFont } from '~/utils/fontClass/index';
import { getSocialLink } from '~/common/services/siteData';

export default function Section03() {
  const { t, i18n } = useTranslation();
  const lock = useRef(false);
  const [links, setLinks] = useState([]);
  const linkCache = useRef({});

  const openChannel = (item) => {
    logAssociationLink(item?.title);
    window.open(item?.url);
  };

  const openDownLink = () => {
    // window.open('https://survey.isnssdk.com/q/49301/9m4Z22AQ/8622/');
    window.open('https://www.marvelsnap.com/creatorprogram');
  };
  const openCreatorprogram = () => {
    location.href = 'https://www.marvelsnap.com/creators';
  };

  const openMediaPage = useCallback(() => {
    logMedia();
    customRedirect('./medialist');
  }, []);

  useEffect(() => {
    const init = async () => {
      let lang = i18n.language;

      if (linkCache.current[lang]) {
        setLinks(linkCache.current[lang]);
        return;
      }
      if (lock.current) {
        return;
      }
      lock.current = true;
      let data = await getSocialLink(lang);
      lock.current = false;
      setLinks(data);
      linkCache.current[lang] = data;
    };
    init();
  }, [t]);

  const launchTo = (link: string) => {
    window.open(link);
  };
  return (
    <div styleName="section03_wrap">
      <p styleName="sec3-title" className={getSpecFont(i18n.language)}>
        {t('follow_marvel_snap')}
      </p>
      {links.length > 0 && (
        <div styleName="follow_snap" id="com_scroll">
          {links.map((item, index) => {
            return (
              <div key={index} styleName="channel_icon_wrap" onClick={() => openChannel(item)}>
                <div styleName="channel_icon_wraps incline_hovers">
                  <img src={item?.image} alt="" />
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div styleName="channel_down" id="sec5">
        {/* <div styleName="con_tent big_incline_hover" onClick={openDownLink}>
          <span styleName="load_span" className={getSpecFont(i18n.language)}>
            {t('content_creators')}
          </span>
        </div> */}
        <div styleName="con_tent big_incline_hover" onClick={openCreatorprogram}>
          <span styleName="load_span" className={getSpecFont(i18n.language)}>
            {t('creator_program')}
          </span>
        </div>

        {i18n.language === 'ja' && (
          <div
            styleName="con_tent big_incline_hover"
            onClick={() =>
              launchTo('https://d3hyqhf8hhr6vv.cloudfront.net/jpn/event-support-program-page/')
            }
          >
            <span styleName="load_span" className={getSpecFont(i18n.language)}>
              大会サポートプログラム
            </span>
          </div>
        )}

        <div styleName="con_tent big_incline_hover" onClick={openMediaPage}>
          <span styleName="load_span" className={getSpecFont(i18n.language)}>
            {t('download_media')}
          </span>
        </div>
      </div>
    </div>
  );
}
